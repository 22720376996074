const SET_MENU_ITEM = 'SET_MENU_ITEM';

export const initialState = {
    activeMenuItem: 1,
    menuMapping: {
        "/": 1,
        "/about" : 2,
        "/services": 3,
        "/pricing": 4,
        "/contact": 5
    }
};

export const setActiveMenuItem = (activeMenuItem) => ({
    type: SET_MENU_ITEM,
    activeMenuItem
});

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MENU_ITEM:
            return {
                ...state,
                activeMenuItem: action.activeMenuItem
            };
        default:
            return state;
    }
};