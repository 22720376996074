import "./header-menu.css";
import fb from './static/facebook.png';
import insta from './static/instagram.png';
import whatsapp from './static/whatsapp.png';

import { Navbar } from "react-bootstrap";
import { useBigScreen } from "../../hooks/useBigScreen";

const SocialLinks = () => {
    const isBigScreen = useBigScreen();

    return (
        <Navbar.Text>
            <a href="https://instagram.com/host.expert?igshid=MzMyNGUyNmU2YQ=="
               target="_blank"
               className="social-media-link">
                <img src={insta} height={isBigScreen ? 30 : 20} alt="insta"/>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61553596245513"
               target="_blank"
               className="social-media-link">
                <img src={fb} height={isBigScreen ? 30 : 20} alt="fb"/>
            </a>
            <a href="https://wa.me/353838533167"
               target="_blank"
               className="social-media-link">
                <img src={whatsapp} height={isBigScreen ? 30 : 20} alt="fb"/>
            </a>
        </Navbar.Text>
    )
};

export default SocialLinks;