import { Col, Row } from "react-bootstrap";
import VioletButton from "../../components/violet-button/violet-button";

const PricingDetails = () => {
    return (
        <Row className="text-center">
            <Col>
                <h3>You unlock your door, we bring the opportunity.</h3>
                <div className="p-3">
                    <VioletButton headerMenuPath={"/contact"} label={"GET IN TOUCH"}/>
                </div>
            </Col>
        </Row>
    )
}

export default PricingDetails;