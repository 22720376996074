import './home-partners.css';
import airbnb from './static/airbnb.png';
import booking from './static/booking.png';
import homeaway from './static/homeaway.png';
import tripadvisor from './static/tripadvisor.png';
import vrbo from './static/vrbo.svg';
import homestay from './static/homestay.svg';

import { Col, Container, Row } from "react-bootstrap";

const HomePartners = ({ isBigScreen }) => {

    const partners = [airbnb, booking, homeaway, tripadvisor, vrbo, homestay];

    const variableToString = varObj => Object.keys(varObj)[0]

    return (
        <section id="home-partners">
            <Container>
                <Row className="pt-5 pb-3">
                    <Col>
                        <h1 className="cardo-font-family cardo-font-family-shadow">
                            Our trusted collaborations
                        </h1>
                    </Col>
                </Row>
                <Row className="pb-3">
                    <Col>
                        <p className="p-shadow">
                            We believe in the power of collaboration. That's why we work with leading names in the
                            hospitality industry, including Airbnb Booking.com or Expedia, to ensure your properties
                            reach a global audience.
                        </p>
                    </Col>
                </Row>
                <Row className="text-center d-flex flex-row pb-3" xs={3} sm={3} lg='auto'>
                    {partners.map((imgSrc, key) => <Col className="flex-grow-1 p-3" key={key}>
                        <img src={imgSrc} height={isBigScreen ? 35 : 25} alt={variableToString({ imgSrc })}/>
                    </Col>)}
                </Row>
            </Container>
        </section>
    );
};

export default HomePartners;