import './home-contact.css';
import HomeContactDesktop from "./home-contact-desktop";
import HomeContactMobile from "./home-contact-mobile";

const HomeContact = ({ height, isBigScreen }) => {
    return (
        <>
            {isBigScreen
                ? <HomeContactDesktop height={height}/>
                : <HomeContactMobile height={height}/>}
        </>
    )
}

export default HomeContact;