import '../home.css';
import { Col, Container, Row } from "react-bootstrap";
import ContactForm from "../../../components/contact-form/contact-form";

const HomeContactDesktop = ({ height }) => {

    return (
        <section id="home-contact" style={{ height: height, maxHeight: 800 }}>
            <Container className="h-100 d-flex flex-column">
                <Row className="pt-5 text-center">
                    <Col>
                        <h1 className="cardo-font-family">Contact us</h1>
                    </Col>
                </Row>
                <Row className="flex-grow-1 m-5">
                    <Col id="home-contact-bg"/>
                    <Col className="ps-5">
                        <ContactForm/>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default HomeContactDesktop;