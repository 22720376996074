import '../home.css';
import { Col, Container, Row } from "react-bootstrap";
import HomeAboutDetails from "./home-about-details";

const HomeAboutMobile = ({ height }) => {

    return (
        <>
            <section id="home-about">
                <Container className="h-100 d-flex flex-column">
                    <Row className="h-100 pt-5 pb-5">
                        <Col className="h-100 d-flex flex-column">
                            <HomeAboutDetails/>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="home-about-mobile-bg" style={{ height: height * 0.4 }}>
                <Container className="h-100">
                    <Row className="h-100">
                        <Col id="home-about-bg" className="m-5"/>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default HomeAboutMobile;