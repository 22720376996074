import './about.css';
import { Col, Container, Row } from "react-bootstrap";
import AboutDetails from "./about-details";

const AboutDesktop = ({ height }) => {

    return (
        <section id="about" style={{ height: height, maxHeight: 800 }}>
            <Container className="h-100 d-flex flex-column">
                <Row className="pt-5 text-center">
                    <Col>
                        <h1 className="cardo-font-family">We are crafting excellent stays</h1>
                    </Col>
                </Row>
                <Row className="flex-grow-1"/>
                <Row className="h-75 pt-3 pb-3">
                    <Col id="about-bg"/>
                    <Col className="h-100 d-flex flex-column ps-5">
                        <Row className="flex-grow-1"/>
                        <AboutDetails/>
                        <Row className="flex-grow-1"/>
                    </Col>
                </Row>
                <Row className="flex-grow-1"/>
            </Container>
        </section>
    );
};

export default AboutDesktop;