import './pricing.css';
import { Col, Container, Row } from "react-bootstrap";
import PricingDetails from "./pricing-details";

const PricingDesktop = ({ height }) => {

    return (
        <section id="pricing" style={{ height: height, maxHeight: 800 }}>
            <Container className="h-100 d-flex flex-column">
                <Row className="flex-grow-1"/>
                <Row className="h-75">
                    <Col id="pricing-bg"/>
                    <Col className="h-100 d-flex flex-column ps-5">
                        <Row className="flex-grow-1"/>
                        <PricingDetails/>
                        <Row className="flex-grow-1"/>
                    </Col>
                </Row>
                <Row className="flex-grow-1"/>
            </Container>
        </section>
    );
};

export default PricingDesktop;