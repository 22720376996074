import './home-about.css';
import HomeAboutDesktop from "./home-about-desktop";
import HomeAboutMobile from "./home-about-mobile";

const HomeAbout = ({ height, isBigScreen }) => {

    return (
        <>
            {isBigScreen
                ? <HomeAboutDesktop height={height}/>
                : <HomeAboutMobile height={height}/>}
        </>
    );
};

export default HomeAbout;