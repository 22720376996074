import "./header-menu.css";
import logo from "./static/logo.png";

import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import SocialLinks from "./social-links";
import { useBigScreen } from "../../hooks/useBigScreen";
import { useStore } from "../../store/store";
import { setActiveMenuItem } from "../../store/reducer";

const HeaderMenu = () => {
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);

    const [{ activeMenuItem, menuMapping }] = useStore();
    const [, dispatch] = useStore();

    const isBigScreen = useBigScreen();

    const toggleMenu = () => setMenuOpen(!menuOpen);

    const handleClose = () => setMenuOpen(false);

    const handleNavSelect = (eventKey) => {
        dispatch(setActiveMenuItem(eventKey));
    }

    useEffect(() => {
        if (location?.pathname) {
            dispatch(setActiveMenuItem(menuMapping[location.pathname]));
        }
    }, [location]);

    return (
        <Navbar expand="lg" bg="white" sticky="top" onToggle={toggleMenu} className="header-menu-lift">
            <Container>
                <Navbar.Brand>
                    <Nav.Link as={Link} to="/" onClick={() => handleNavSelect(1)}>
                        <img src={logo} height={isBigScreen ? 80 : 50} alt="Logo"/>
                    </Nav.Link>
                </Navbar.Brand>
                {!isBigScreen && <SocialLinks/>}
                <Navbar.Toggle/>
                <Navbar.Offcanvas placement="end" show={menuOpen} onHide={handleClose} restoreFocus={false}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title as="div">
                            <SocialLinks/>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-center flex-grow-1"
                             variant="pills" activeKey={activeMenuItem} onSelect={handleNavSelect}>
                            <Nav.Link eventKey={menuMapping["/"]} as={Link} to="/" onClick={handleClose}>Home</Nav.Link>
                            <Nav.Link eventKey={menuMapping["/about"]} as={Link} to="/about" onClick={handleClose}>About</Nav.Link>
                            <Nav.Link eventKey={menuMapping["/services"]} as={Link} to="/services"
                                      onClick={handleClose}>Services</Nav.Link>
                            <Nav.Link eventKey={menuMapping["/pricing"]} as={Link} to="/pricing" onClick={handleClose}>Pricing</Nav.Link>
                            <Nav.Link eventKey={menuMapping["/contact"]} as={Link} to="/contact" onClick={handleClose}>Contact</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
                {isBigScreen && <SocialLinks/>}
            </Container>
        </Navbar>
    )
};

export default HeaderMenu;