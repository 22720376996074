import '../home.css';

const HomeHeaderText = ({ isBigScreen }) => {

    return (
        <div className={isBigScreen ? "home-header-animation" : ""}>
            <h1 className="cardo-font-family cardo-font-family-shadow">Welcome to Host Expert</h1>
            <p className="p-shadow header-description">Unlock Your Listing's Potential</p>
        </div>
    );
};

export default HomeHeaderText;