import { Col, Row } from "react-bootstrap";
import VioletButton from "../../../components/violet-button/violet-button";

const HomeAboutDetails = () => {
    return (
        <Row className="text-center">
            <Col>
                <h1 className="cardo-font-family pb-3">About us</h1>
                <p className="pb-3">
                    With a passion for hospitality and a commitment to excellence, we take your
                    investment to new heights. Discover a partner that goes beyond managing spaces — we
                    create memories
                </p>
                <VioletButton headerMenuPath={"/about"} label={"Learn more..."}/>
            </Col>
        </Row>
    )
}

export default HomeAboutDetails;