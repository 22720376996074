import './contact-form.css';
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import { useRef, useState } from "react";
import GoogleCaptchaV2 from "./contact-form-captcha";

const ContactForm = () => {
    const [validated, setValidated] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showWarningAlert, setShowWarningAlert] = useState(false);
    const [emailValue, setEmailValue] = useState("");
    const [nameValue, setNameValue] = useState("");
    const [subjectValue, setSubjectValue] = useState("");
    const [textValue, setTextValue] = useState("");
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [isCaptchaInvalid, setIsCaptchaInvalid] = useState(true);
    const captchaRef = useRef(null)

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === true && captchaRef?.current?.getValue()) {
            const token = captchaRef.current.getValue();
            submit(token);
            setNameValue("");
            setEmailValue("");
            setSubjectValue("");
            setTextValue("");
            setValidated(false);
        } else {
            setValidated(true);
        }
    };

    const valueFieldSetters = {
        name: setNameValue,
        email: setEmailValue,
        subject: setSubjectValue,
        text: setTextValue
    }

    const setValue = (id, value) => {
        valueFieldSetters[id](value);
        setShowSuccessAlert(false);
        setShowWarningAlert(false);
    }

    const submit = (token) => {
        setSubmitInProgress(true);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: nameValue,
                email: emailValue,
                subject: subjectValue,
                text: textValue,
                token: token
            })
        };
        fetch("https://828ynz3dsj.execute-api.eu-west-1.amazonaws.com/v1/contact-us", requestOptions)
            .then(response => {
                if (response.status === 200) {
                    setShowSuccessAlert(true);
                } else {
                    setShowWarningAlert(true);
                }
                console.log(response.json());
            })
            .catch(err => {
                console.error(err);
                setShowWarningAlert(true);
            })
            .finally(() => setSubmitInProgress(false));
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Control size="lg" type="text" placeholder="Name"
                          required id='name' value={nameValue}
                          onChange={event => setValue(event.target.id, event.target.value)}
                          className="home-contact-form-input mb-3 p-3"/>
            <Form.Control size="lg" type="email" placeholder="Email"
                          required id='email' value={emailValue}
                          onChange={event => setValue(event.target.id, event.target.value)}
                          className="home-contact-form-input mb-3 p-3"/>
            <Form.Control size="lg" type="subject" placeholder="Subject"
                          required id='subject' value={subjectValue}
                          onChange={event => setValue(event.target.id, event.target.value)}
                          className="home-contact-form-input mb-3 p-3"/>
            <Form.Control size="lg" as="textarea" rows={3} placeholder="Type your message here..."
                          required id='text' value={textValue}
                          onChange={event => setValue(event.target.id, event.target.value)}
                          className="home-contact-form-input mb-3 p-3"/>
            <GoogleCaptchaV2
                captchaRef={captchaRef}
                isCaptchaInvalid={isCaptchaInvalid}
                setIsCaptchaInvalid={setIsCaptchaInvalid}/>
            <div className="text-center pb-3">
                <Button size="lg" className="purple-button" type="submit" disabled={isCaptchaInvalid}>
                    <span>Submit</span>
                </Button>
                {submitInProgress && <div className="pt-3 pb-3"><Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner></div>}
            </div>
            <Alert variant="success" dismissible show={showSuccessAlert}
                   onClose={() => setShowSuccessAlert(false)}>
                <p className="home-contact-form-alert">
                    Thank you for your message. We will contact you soon!
                </p>
            </Alert>
            <Alert variant="warning" dismissible show={showWarningAlert}
                   onClose={() => setShowWarningAlert(false)}>
                <p className="home-contact-form-alert">
                    Service unavailable. Please try again later.
                </p>
            </Alert>
        </Form>
    )
}

export default ContactForm;