import './contact.css';
import { Col, Container, Row } from "react-bootstrap";
import { useBigScreen } from "../../hooks/useBigScreen";
import ContactForm from "../../components/contact-form/contact-form";

const Contact = () => {
    const isBigScreen = useBigScreen();

    return (
        <Container>
            <article id="contact">
                <Row className="pt-5 pb-3 text-center">
                    <Col>
                        <h1 className="cardo-font-family">Let's Have a Conversation</h1>
                    </Col>
                </Row>
                <Row className="pb-3">
                    <Col>
                        <p className="text-center">
                            We're here to listen, assist, and collaborate. Reach out to Host Expert for any inquiries,
                            partnership opportunities, or to discuss how we can elevate your property management
                            experience. Your success begins with a simple conversation. Let's connect and explore the
                            possibilities together.
                        </p>
                    </Col>
                </Row>
            </article>
            <section id="contact-form">
                <Row className="pb-5">
                    {isBigScreen && <Col className="col-lg-2"/>}
                    <Col className={isBigScreen ? "col-lg-8" : ""}>
                        <ContactForm/>
                    </Col>
                    {isBigScreen && <Col className="col-lg-2"/>}
                </Row>
            </section>
        </Container>
    );
};

export default Contact;