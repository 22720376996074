import '../home.css';
import { Col, Container, Row } from "react-bootstrap";
import ContactForm from "../../../components/contact-form/contact-form";

const HomeContactMobile = ({ height }) => {

    return (
        <>
            <section id="home-contact">
                <Container className="h-100 d-flex flex-column">
                    <Row className="pt-5 text-center">
                        <Col>
                            <h1 className="cardo-font-family">Contact us</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ContactForm/>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="home-contact-mobile-bg" style={{ height: height * 0.4 }}>
                <Container className="h-100">
                    <Row className="h-100">
                        <Col id="home-contact-bg" className="m-5"/>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default HomeContactMobile;