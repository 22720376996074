import './about.css'
import { useBigScreen } from "../../hooks/useBigScreen";
import AboutDesktop from "./about-desktop";
import AboutMobile from "./about-mobile";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Container } from "react-bootstrap";
import VioletButton from "../../components/violet-button/violet-button";

const About = () => {

    const isBigScreen = useBigScreen();
    const { height } = useWindowDimensions();

    return (
        <>
            {isBigScreen
                ? <AboutDesktop height={height}/>
                : <AboutMobile height={height}/>}
            <article id="about-details">
                <Container>
                    <h3 className="pb-3">Transform your home into a business</h3>
                    <p className="pb-3">
                        We are excited about unlocking the potential of every home by redefining the property management
                        experience through our end-to-end service with no request too much or too big, delivering
                        thoughtfully designed interiors, smart algorithmic pricing decisions and a personal service.
                    </p>
                    <p className="pb-3">
                        Whether guests are staying for a short break, a long business engagement or choose to make one
                        of our managed properties their home in the city, we aim to offer a flawless, unique experience.
                    </p>
                    <p className="pb-3">
                        Host Expert is active currently in Dublin and is growing at a rapid rate. Our hosts love us
                        for the superior income we continue to deliver which is being driven by working with more than
                        10 global leading brands, building relationships with global companies, utilising our smart
                        pricing strategy and local market expertise.
                    </p>
                    <div className="text-center pb-5">
                        <VioletButton headerMenuPath={"/contact"} label={"GET IN TOUCH"}/>
                    </div>
                </Container>
            </article>
        </>
    );
};

export default About;