import { Col, Row } from "react-bootstrap";

const AboutDetails = () => {
    return (
        <Row className="text-center">
            <Col>
                <p className="pb-3">
                    At Host Expert Property Management, we're not just in the business of managing properties; we're in
                    the business of crafting unforgettable experiences. With a passion for hospitality and a commitment
                    to excellence, we take your investment to new heights. Discover a partner that goes beyond managing
                    spaces — we create memories.
                </p>
            </Col>
        </Row>
    )
}

export default AboutDetails;