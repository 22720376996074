import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./components/app";
import { StoreProvider } from "./store/store";
import { initialState, reducer } from "./store/reducer";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <StoreProvider initialState={initialState} reducer={reducer}>
            <App/>
        </StoreProvider>
    </React.StrictMode>
);
