import '../home.css';
import './home-services-item.css';

import { Figure } from "react-bootstrap";

const HomeServicesItem = ({ imgSrc, title }) => {

    return (
        <Figure>
            <Figure.Image
                width={60}
                alt={title}
                src={imgSrc}
            />
            <Figure.Caption>
                <p className="p-shadow">{title}</p>
            </Figure.Caption>
        </Figure>
    );
};

export default HomeServicesItem;