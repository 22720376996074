import '../home.css';
import { Col, Container, Row } from "react-bootstrap";
import HomeAboutDetails from "./home-about-details";

const HomeAboutDesktop = ({ height }) => {

    return (
        <section id="home-about" style={{ height: height - 28, maxHeight: 800 }}>
            <Container className="h-100 d-flex flex-column">
                <Row className="flex-grow-1"/>
                <Row className="h-75 pt-5 pb-5">
                    <Col id="home-about-bg"/>
                    <Col className="h-100 d-flex flex-column ps-5">
                        <Row className="flex-grow-1"/>
                        <HomeAboutDetails/>
                        <Row className="flex-grow-1"/>
                    </Col>
                </Row>
                <Row className="flex-grow-1"/>
            </Container>
        </section>
    );
};

export default HomeAboutDesktop;