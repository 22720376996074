import './pricing.css'
import { useBigScreen } from "../../hooks/useBigScreen";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Col, Container, Row } from "react-bootstrap";
import PricingDesktop from "./pricing-desktop";
import PricingMobile from "./pricing-mobile";

const Pricing = () => {
    const isBigScreen = useBigScreen();
    const { height } = useWindowDimensions();

    return (
        <>
            <article id="about-details">
                <Container>
                    <Row className="pt-5 pb-3 text-center">
                        <Col>
                            <h1 className="cardo-font-family">Our Fair Approach: Your Success is Our Reward</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="text-center">
                                At Host Expert, we believe in earning when you do, aligning our success with yours.
                                Discover how our competitive rates and tailored solutions ensure that you thrive while we
                                manage and optimize your properties.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </article>
            {isBigScreen
                ? <PricingDesktop height={height}/>
                : <PricingMobile height={height}/>}
        </>
    );
};

export default Pricing;