import './services.css'
import profiPhoto from './static/professional-photography.png';
import guestCommunication from './static/24-7.png';
import personalWelcome from './static/personal-welcome.png';
import premiumAmenities from './static/premium-amenities.png';
import interiorDesign from './static/interior-design.png';
import invest from './static/price-optimization.png';

import { Col, Container, Row } from "react-bootstrap";
import VioletButton from "../../components/violet-button/violet-button";
import ServicesItem from "./services-item";


const Services = () => {

    const services = [
        {
            imgSrc: profiPhoto,
            title: "Listing Creation and Professional Photography",
            text: "Captivate potential guests with our Listing Creation and Professional Photography service. " +
                "We transform your property into an irresistible showcase, combining compelling descriptions with " +
                "high-quality visuals that highlight its unique features. Elevate your listing's appeal and attract " +
                "more bookings with our expert touch, ensuring your property stands out in the competitive market."
        },
        {
            imgSrc: guestCommunication,
            title: "24/7 Support, Guest Communication and Screening",
            text: "Experience peace of mind with our 24/7 Support, where hospitality meets efficiency. " +
                "Our team is available 24/7 to answer guest queries and offer support. " +
                "We answer 99% of messages within 1 hour, so your guests won’t be waiting around."
        },
        {
            imgSrc: personalWelcome,
            title: "Personal Welcome or Self Check-in Options",
            text: "Experience the freedom of choice with our Personal Welcome or Self Check-in Options. " +
                "Whether you prefer a warm, personalized greeting upon arrival or the convenience of a seamless " +
                "self-check-in process, Host Expert Property Management caters to your preferences. Our goal is to " +
                "ensure your guests feel welcome and in control, setting the stage for a delightful stay from the very " +
                "beginning."
        },
        {
            imgSrc: premiumAmenities,
            title: "Premium Amenities, Cleaning, Linen, Laundry Services",
            text: "Indulge your guests in a world of luxury with our Premium Amenities, Cleaning, Linen, and Laundry " +
                "Services. Elevate your property's appeal through meticulous cleaning, high-quality linens, and expert " +
                "laundry care. From exquisite toiletries to flawless cleanliness, we ensure that every detail is " +
                "impeccably managed, providing your guests with a truly unforgettable experience of comfort and " +
                "sophistication."
        },
        {
            imgSrc: interiorDesign,
            title: "Property Maintenance and Interior Design",
            text: "Transform your property into a captivating space that stands out in the market. Our Property " +
                "Maintenance and Interior Design service ensure your investment remains not only structurally sound " +
                "but also visually stunning. From routine maintenance checks to expert interior design consultations, " +
                "we meticulously care for your property. Elevate its appeal, functionality, and overall value with our " +
                "dedicated team, ensuring that every corner reflects the essence of comfort and style. Trust us to " +
                "enhance both the longevity and aesthetic allure of your investment."
        },
        {
            imgSrc: invest,
            title: "Price Optimization and Invest",
            text: "Unlock the full potential of your property portfolio with our Price Optimization and Investment " +
                "expertise. At Host Expert Property Management, we leverage data-driven insights and market analysis to " +
                "ensure that your property is priced competitively, maximizing your returns. Whether you're a seasoned " +
                "investor or just starting, our team provides strategic guidance to enhance your investment portfolio. " +
                "Trust us to navigate the dynamic market, optimize pricing for peak performance, and guide you towards " +
                "smart investment decisions. Elevate your real estate journey with Host Expert – where every detail is " +
                "tailored for your success."
        }]

    return (
        <section id="services">
            <Container>
                <Row className="pt-5 pb-5 text-center">
                    <Col>
                        <h1 className="cardo-font-family">Tailored solutions for best experiences</h1>
                    </Col>
                </Row>
                {services.map(({ imgSrc, title, text }, index) =>
                    <ServicesItem key={index} imgSrc={imgSrc} title={title} text={text} imageRight={index % 2 !== 0}
                                  hasBottomBorder={index < services.length - 1}/>)}
                <Row className="pt-5 pb-5 text-center">
                    <Col>
                        <VioletButton headerMenuPath={"/contact"}
                                      label={"Get in touch with us and see how we can help!"}/>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Services;