import './app.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "../../screens/home";
import HeaderMenu from "../header-menu";
import About from "../../screens/about";
import Footer from "../footer";
import Services from "../../screens/services";
import Pricing from "../../screens/pricing";
import ScrollToTop from "../scroll-to-top";
import Contact from "../../screens/contact";

const App = () => {

    return (
        <>
            <Router>
                <ScrollToTop/>
                <div className="content">
                    <HeaderMenu/>
                    <Routes>
                        <Route path='/' element={<Home/>}/>
                        <Route path='/about' element={<About/>}/>
                        <Route path='/services' element={<Services/>}/>
                        <Route path='/pricing' element={<Pricing/>}/>
                        <Route path='/contact' element={<Contact/>}/>
                    </Routes>
                </div>
                <Footer/>
            </Router>
        </>
    )
};

export default App;