import './home-header.css';
import { Col, Container, Row } from "react-bootstrap";
import HomeHeaderText from "./home-header-text";

const HomeHeader = ({ height, isBigScreen }) => {

    return (
        <section id="home-header" style={{ height: isBigScreen ? height - 106 : height * 0.4 }}>
            <Container className="h-100 d-flex flex-column">
                <Row className="flex-grow-1">
                </Row>
                <Row className={isBigScreen ? "flex-grow-1" : ""}>
                    <Col className="text-center">{!isBigScreen && <HomeHeaderText isBigScreen={isBigScreen}/>}</Col>
                </Row>
                <Row className={isBigScreen ? "h-50" : "flex-grow-1"}>
                    <Col>{isBigScreen && <HomeHeaderText isBigScreen={isBigScreen}/>}</Col>
                </Row>
            </Container>
        </section>
    );
};

export default HomeHeader;