import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/store";
import { setActiveMenuItem } from "../../store/reducer";

const VioletButton = ({ headerMenuPath, label }) => {
    const navigate = useNavigate();

    const [{ menuMapping }] = useStore();
    const [, dispatch] = useStore();

    const handleButtonClick = (event) => {
        event.preventDefault();
        dispatch(setActiveMenuItem(menuMapping[headerMenuPath]));
        navigate(headerMenuPath);
    }

    return (
        <Button size="lg" className="purple-button" onClick={handleButtonClick}>
            <span>{label}</span>
        </Button>
    )
}

export default VioletButton;