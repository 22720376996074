import './pricing.css';
import { Col, Container, Row } from "react-bootstrap";
import PricingDetails from "./pricing-details";

const PricingMobile = ({ height }) => {

    return (
        <>
            <section id="pricing">
                <Container className="h-100 d-flex flex-column">
                    <Row className="h-100 pt-5">
                        <Col className="h-100 d-flex flex-column">
                            <PricingDetails/>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="pricing-mobile-bg" style={{ height: height * 0.4 }}>
                <Container className="h-100">
                    <Row className="h-100">
                        <Col id="pricing-bg" className="m-5"/>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default PricingMobile;