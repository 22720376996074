import './carousel-item.css';

const CarouselItem = ({ imgSrc, text, isBigScreen }) => {

    return (
        <div className="head-text">
            <div className="head-image">
                <img src={imgSrc} alt="carousel_item" style={{
                    maxWidth: '100%',
                    maxHeight: '100%'
                }}/>
            </div>
            <div className='text-on-image'>
                {text && <p className="p-shadow"><i>"{text}"</i></p>}
            </div>
        </div>
    )
};

export default CarouselItem;