import '../home.css';

import { Carousel } from "react-bootstrap";
import CarouselItem from "./carousel-item";
import { useState } from "react";

const HomeTestimonialsCarousel = ({ isBigScreen, imgSrc, emptySrc, testimonials }) => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel fade interval={null} variant="dark" activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <CarouselItem imgSrc={imgSrc} isBigScreen={isBigScreen}/>
            </Carousel.Item>
            {testimonials.map((text, key) => <Carousel.Item key={key}>
                <CarouselItem imgSrc={emptySrc} text={text} isBigScreen={isBigScreen}/>
            </Carousel.Item>)}
        </Carousel>
    );
};

export default HomeTestimonialsCarousel;