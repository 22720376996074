import './about.css';
import { Col, Container, Row } from "react-bootstrap";
import AboutDetails from "./about-details";

const AboutMobile = ({ height }) => {

    return (
        <>
            <section id="about">
                <Container className="h-100 d-flex flex-column">
                    <Row className="pt-5 text-center">
                        <Col>
                            <h1 className="cardo-font-family">We are crafting excellent stays</h1>
                        </Col>
                    </Row>
                    <Row className="h-100 pt-5">
                        <Col className="h-100 d-flex flex-column">
                            <AboutDetails/>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="about-mobile-bg" style={{ height: height * 0.4 }}>
                <Container className="h-100">
                    <Row className="h-100">
                        <Col id="about-bg" className="m-5"/>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default AboutMobile;