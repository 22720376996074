import hosts from './static/hosts.png';
import empty from './static/empty.png';

import { Col, Container, Row } from "react-bootstrap";
import HomeTestimonialsCarousel from "./home-testimonials-carousel";

const HomeTestimonials = ({ isBigScreen }) => {

    return (
        <section id="home-testimonials">
            <Container>
                <Row className="pt-5 text-center">
                    <Col>
                        <h1 className="cardo-font-family">Client Testimonials</h1>
                        <p>Hear from our delighted Hosts</p>
                    </Col>
                </Row>
                <Row className="justify-content-center flex-grow-1" xs={1} sm={1} lg={2}>
                    <Col className="p-5">
                        <HomeTestimonialsCarousel
                            isBigScreen={isBigScreen} imgSrc={hosts} emptySrc={empty}
                            testimonials={[
                                "Host Expert has truly elevated our partnership. Their dedication to excellence has boosted our occupancy rates and enhanced our reputation. A valuable collaboration that we highly recommend.",
                                "Host Expert has turned my investments into hassle-free, profitable ventures. Their expertise in property care and guest experience optimization has significantly increased my revenue. I highly recommend Host Expert to fellow property owners."
                            ]}/>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default HomeTestimonials;