import './service-item.css';
import { Col, Row } from "react-bootstrap";
import { useBigScreen } from "../../hooks/useBigScreen";

const HomeServicesItem = ({ imgSrc, title, text, imageRight, hasBottomBorder }) => {

    const isBigScreen = useBigScreen();

    return (
        <Col className={(hasBottomBorder ? "service-item-border " : "") + "pb-3 pt-3"}>
            <Row className={!isBigScreen ? "text-center" : ""} xs={1} sm={1} lg={2}>
                {!isBigScreen && <Col><h3>{title}</h3></Col>}
                <Col className={"text-center" + (isBigScreen ? " w-25 " : " p-3")
                    + (imageRight ? " order-lg-2" : " order-lg-1")}>
                    <img src={imgSrc} width={isBigScreen ? 300 : 250} alt={title} className="service-item-img"/>
                </Col>
                <Col
                    className={(isBigScreen ? "w-75 " : "") + "d-flex flex-column"
                        + (imageRight ? " order-lg-1" : " order-lg-2")}>
                    <Row className="flex-grow-1"/>
                    <Row>
                        <article id={title}>
                            {isBigScreen && <h3>{title}</h3>}
                            <p>{text}</p>
                        </article>
                    </Row>
                    <Row className="flex-grow-1"/>
                </Col>
            </Row>
        </Col>
    );
};

export default HomeServicesItem;