import './home-services.css';
import listingCreation from './static/listing-creation.png';
import profPhotography from './static/prof-photography.png';
import maintenance from './static/maintenance.png';
import _24_7 from './static/24-7.png';
import communication from './static/communication.png';
import interiorDesign from './static/interior-design.png';
import welcome from './static/welcome.png';
import priceOptimization from './static/price-optimization.png';
import laundry from './static/laundry.png';

import { Col, Container, Row } from "react-bootstrap";
import HomeServicesItem from "./home-services-item";
import VioletButton from "../../../components/violet-button/violet-button";

const HomeServices = ({ isBigScreen }) => {

    const services = [
        { imgSrc: listingCreation, title: "Listing Creation" },
        { imgSrc: profPhotography, title: "Professional Photography" },
        { imgSrc: maintenance, title: "Property Maintenance" },
        { imgSrc: _24_7, title: "24/7 Support" },
        { imgSrc: communication, title: "Guest Communication and Screening" },
        { imgSrc: interiorDesign, title: "Interior Design" },
        { imgSrc: welcome, title: "Personal Welcome or Self Checkin" },
        { imgSrc: priceOptimization, title: "Price Optimization" },
        { imgSrc: laundry, title: "Cleaning, Linen and Laundry Service" }]

    return (
        <section id="home-services" style={{ backgroundRepeat: isBigScreen ? 'no-repeat' : 'round' }}>
            <Container>
                <Row className="pt-5 pb-5 text-center">
                    <Col>
                        <h1 className="cardo-font-family cardo-font-family-shadow">Services</h1>
                    </Col>
                </Row>
                <Row className="text-center d-flex flex-row pb-3" xs={1} sm={1} lg={3}>
                    {services.map((item, key) => <Col className="p-3" key={key}>
                        <HomeServicesItem imgSrc={item.imgSrc} title={item.title}/>
                    </Col>)}
                </Row>
                <Row className="pb-5 text-center">
                    <Col>
                        <VioletButton headerMenuPath={"/services"} label={"Learn more..."}/>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default HomeServices;