import "./footer.css";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <section id="footer" className="pt-4 pb-3">
            <div className="footer-copy-right text-center">
                <p>Host Expert is an independent third party and is not affiliated or endorsed by Airbnb, its affiliates, or any other booking platform.</p>
                <p>© {currentYear} Host Expert. All Rights Reserved. Powered by<span> </span>
                    <a href="https://maximdomentii.com" target="_blank" className="powered-by">
                        maximdomentii.com
                    </a>
                </p>
            </div>
        </section>
    )
};

export default Footer;