import './home.css';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useBigScreen } from "../../hooks/useBigScreen";
import HomeHeader from "./home-header/home-header";
import HomeAbout from "./home-about/home-about";
import HomePartners from "./home-partners/home-partners";
import HomeTestimonials from "./home-testimonials/home-testimonials";
import HomeServices from "./home-services/home-services";
import HomeContact from "./home-contact/home-contact";

const Home = () => {

    const { height } = useWindowDimensions();

    const isBigScreen = useBigScreen();

    return (
        <>
            <HomeHeader height={height} isBigScreen={isBigScreen}/>
            <HomeAbout height={height} isBigScreen={isBigScreen}/>
            <HomePartners isBigScreen={isBigScreen}/>
            <HomeTestimonials isBigScreen={isBigScreen}/>
            <HomeServices isBigScreen={isBigScreen}/>
            <HomeContact height={height} isBigScreen={isBigScreen}/>
        </>
    );
};

export default Home;